<template>
  <Datatable :api-endpoint="ENDPOINTS.DATATABLES.ERP.ITEMS" :custom-control-buttons="customControlButtons"
             :datatable-headers="datatableHeaders"
             :excel-columns="excelColumns"
             :permissionDelete="this.$store.getters['permissions/checkPermission']('ware_delete')"
             :permissionEdit="this.$store.getters['permissions/checkPermission']('ware_edit')"
             :show-toggle-status="this.$store.getters['permissions/checkPermission']('ware_edit')"
             @deleteEntries="deleteEntries" @deleteEntry="deleteEntry" @disable="disable"
             @editEntry="editEntry" @enable="enable" @toggleStatus="toggleStatus"
             enable-multi-sort
             :searchPayload="searchPayload"
             :datatable-attach-search="datatableAttachSearch"
             ref="itemDatatable"
             excelFileName="Artikelexport"
             show-delete-buttons
             show-edit-buttons
             show-select-checkbox
  >
    <template v-slot:item.sellPriceNetto="{item}">
      {{item.sellPriceNetto | currency}}
    </template>
    <template v-slot:item.sellPriceBrutto="{item}">
      {{item.sellPriceBrutto | currency}}
    </template>
    <template v-slot:item.taxRate="{item}">
      {{Number(item.taxRate).toFixed(2)}} %
    </template>
  </Datatable>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

import Datatable from "../../datatable/Datatable";
import {mapState} from "vuex";

export default {
  name: "Items",

  components: {
    Datatable,
  },

  data() {
    return {
      ENDPOINTS,
      suppliers: [],
      producers: [],
      customControlButtons: [
        {
          title: this.$t('generic.lang_enable'),
          handler: "enable",
          layout: {
            icon: "check",
            color: "success"
          }
        },
        {
          title: this.$t('generic.lang_disable'),
          handler: "disable",
          layout: {
            icon: "close",
            color: "error"
          }
        }
      ],
      datatableHeaders: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          width: 80,
          hide: true
        },
        {text: this.$t('erp.lang_ware_create_id'), value: 'ean', searchable: true, searchCol: 6},
        {text: this.$t('erp.lang_posItemName'), value: 'name', searchable: true, sort: "asc", searchCol: 6},
        {text: this.$t('erp.internal_wareNo'), value: 'internWareID'},
        /*
        * {text: 'Liefer. Best. Nr1', value: 'lieferanten_1_best_nr'},
        * {text: 'Liefer. Best. Nr2', value: 'lieferanten_2_best_nr'},
        * */
        {text: this.$t('generic.lang_waregroup'), value: 'itemGroup'},
        {text: this.$t('erp.lang_stockinventory'), value: 'stock'},
        {text: this.$t('erp.lang_vk_netto'), value: 'sellPriceNetto'},
        {text: this.$t('erp.lang_vk_brutto'), value: 'sellPriceBrutto'},
        {text: this.$t('accounting.lang_taxRateinPercent'), value: 'taxRate'},
        {text: this.$t('generic.lang_status'), value: 'togglestatus', align: 'center'},
        {text: 'addOnBarcodesCode', value: 'addOnBarcodesCode', hide: true, align: 'center'},
        {text: 'itemIsVariation', value: 'itemIsVariation', hide: true, align: 'center'},
        {text: 'itemIsAddOnBarcode', value: 'itemIsAddOnBarcode', hide: true, align: 'center'},
      ],
      datatableAttachSearch: [
        {text: this.$t('generic.lang_waregroup'), value: 'itemGroup', searchType: 'multiselect', searchCol: 4},
        {text: this.$t('erp.lang_nav_supplier'), value: 'supplier_id', searchType: 'singleselect', searchCol: 4},
        {text: this.$t('erp.lang_nav_manufacturer'), value: 'producer_id', searchType: 'singleselect', searchCol: 4}
      ],
      excelColumns: [
        {
          label: this.$t('erp.lang_ware_create_id'),
          field: "ean",
        },
        {
          label: this.$t('erp.lang_posItemName'),
          field: "name",
        },
        {
          label: this.$t('erp.internal_wareNo'),
          field: "internID",
        },
        /*{
            label: "Liefer. Best. Nr1",
            field: "lieferanten_1_best_nr",
        },
        {
            label: "Lierfer. Best. Nr2",
            field: "lieferanten_2_best_nr",
        },*/
        {
          label: this.$t('generic.lang_waregroup'),
          field: "itemGroup",
        },
        {
          label: this.$t('erp.lang_stockinventory'),
          field: "stock",
        },
        {
          label: this.$t('erp.lang_vk_netto'),
          field: "sellPriceNet",
        },
        {
          label: this.$t('erp.lang_vk_brutto'),
          field: "sellPriceBrutto",
        },
        {
          label: this.$t('accounting.lang_taxRateinPercent'),
          field: "taxRate",
        }
      ],
      searchPayload: {}
    }
  },

  async mounted() {
    await this.getSuppliers();
    await this.getProducers();
    await this.getSearchPayload();
  },

  methods: {
    async getSearchPayload() {
      let data = {};

      //ITEMGROUPS
      let itemgroups = await this.$store.dispatch("itemgroups/getItemgroups");

      data["itemGroup"] = itemgroups.map((itemgroup) => {
        return {
          id: itemgroup.id,
          name: itemgroup.name
        }
      }).sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      //SUPPLIERS
      data["supplier_id"] = this.suppliers.map((supplier) => {
        return {
          id: supplier.id,
          name: supplier.supplier_name
        }
      }).sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      //PRODUCERS
      data["producer_id"] = this.producers.map((producer) => {
        return {
          id: producer.id,
          name: producer.producer_name
        }
      }).sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      this.searchPayload = data;
    },

    getSuppliers() {
      return this.axios.post(ENDPOINTS.ERP.SUPPLIERS.GETALL).then((res) => {
        if (res.data.STATUS === "SUCCESS")
          this.suppliers = res.data.suppliers;
      });
    },
    getProducers() {
      return this.axios.post(ENDPOINTS.ERP.PRODUCERS.GETALL).then((res) => {
        if (res.data.success)
          this.producers = res.data.producers;
      });
    },
    statusSwitcher(IDs, status) {
      this.axios.post(ENDPOINTS.ERP.ITEM.TOGGLESTATUS, {
        itemIDs: IDs,
        status: status
      }).then((res) => {
        // //console.log(res)
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_ActionSuccessful'),
            color: "success"
          });

          this.$refs.itemDatatable.getDataFromApi();
          this.$refs.itemDatatable.resetSelectedRows();
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      })
    },
    toggleStatus(entry) {
      this.statusSwitcher([entry.id], entry.togglestatus === "1")
    },
    enable(ids) {
      this.statusSwitcher(ids, 1)
    },
    disable(ids) {
      this.statusSwitcher(ids, 0)
    },
    editEntry(entry) {
      let parentId = entry.itemIsVariation ? entry.itemIsVariation : entry.itemIsAddOnBarcode;
      if(parentId) {
        this.id = parentId;
      }else {
        this.id = entry.id;
        this.text = entry.name;
      }
      this.$router.push({name: 'erp.baseData.items.edit', params: {id: parseInt(this.id)}})
    },
    deleteData: function (idsToDelete = []) {
      this.$swal({
        title: this.$t('erp.lang_ware_delete_sub'),
        text: this.$t('erp.lang_ware_delete_confirm'),
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(parseInt(this.id));
          }
          this.axios.post(ENDPOINTS.ERP.ITEM.DELETE, {
            deleteItemID: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_itemSuccessfulDeleted'),
                color: "success"
              });


              this.$refs.itemDatatable.getDataFromApi();
              this.$refs.itemDatatable.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    deleteEntry(entry) {
      this.id = entry.id;
      this.deleteData();
    },
    deleteEntries(entries) {
      this.deleteData(entries);
    },
  }
}
</script>
