<template>
  <div>
    <page-title :heading="$t('erp.lang_ware_search') "
                :permissionAdd="this.$store.getters['permissions/checkPermission']('ware_create') && !this.hybrid"
                :showHelpButton="false" :showAddButton="true" url-add="/erp/baseData/item/create"
                :subheading="$t('erp.lang_ware_search')" :icon="icon"/>


    <div class="app-main__inner">
      <Items/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import Items from "../../../components/erp/baseData/Items";

export default {
  components: {
    PageTitle,
    Items
  },
  data: () => ({
    icon: 'pe-7s-search icon-gradient bg-tempting-azure',
  }),
  computed:{
    hybrid(){ return process.env.VUE_APP_HYBRID == 'true' || this.$store.getters["permissions/checkHybridApp"]},
  }
}
</script>